import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

if (!window.location.host.includes("sonadier.test") && !window.sentry_initialized) {
  Sentry.init({
    dsn: "https://e6d134cdc0b74dafb087ee527b019758@o931562.ingest.sentry.io/5880517",

    tunnel: "/api/sentry-tunnel",

    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 0.125,
  })

  window.Sentry_setUser = Sentry.setUser
  window.Sentry_setTag = Sentry.setTag
  window.Sentry_captureException = Sentry.captureException

  // window.Raven.config('https://f15edd6fca824a4c8d683539747159ad@sentry.sonadier.io/2').install()
  window.sentry_initialized = true
}
